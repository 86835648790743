import React, { useCallback, useState } from "react";
import { Card, Spinner } from "theme-ui";
import DefaultLayout from "../../../layouts/default";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);

  const onLoad = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  return (
    <DefaultLayout>
      <Card bordered={false} style={{ textAlign: "center" }}>
        <div>
          {loading && <Spinner />}
          <iframe
            title="Dashboard"
            width="100%"
            height="100%"
            src="https://datastudio.google.com/embed/reporting/1VDWfiRQh_WHyFqo8KfCHQtq7_ufaLUpz/page/9hKc"
            frameBorder={0}
            style={{ height: "500vw" }}
            scrolling="no"
            onLoad={onLoad}
          />
        </div>
      </Card>
    </DefaultLayout>
  );
};

export default Dashboard;
